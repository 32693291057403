import React from 'react'
import {Container,TextContainer,TextWrapper,HeaderContainer,Icon} from './elements'
import {FaTimes} from 'react-icons/fa'

const CoronaUpdate = ({isOpenCorona, toggleCorona})=>{


    return(
        
            
        <Container isOpenCorona = {isOpenCorona} onClick = {toggleCorona}>
            <TextContainer>
                <HeaderContainer>
                    <h1>Corona virus update</h1>
                    <Icon>
                    <FaTimes />
                    </Icon>
        
                </HeaderContainer>

                <TextWrapper >
                    <p  >Beste ouders/ verzorgers,
                        <br/>
                        <br/>
                        ⁃ Bij ziekte of ziekteverschijnselen geen toegang tot de praktijk
                        <br/>
                        ⁃ Patiënten kunnen alleen met 1 begeleider naar binnen
                        <br/>
                        ⁃ De handen dienen gedesinfecteerd te worden bij binnenkomst
                        <br/>
                        ⁃ De wc’s en poetsruimte zijn buiten gebruik
                        <br/>
                        ⁃ Er ligt tijdelijk geen speelgoed in de wachtkamer
                        <br/>
                        ⁃ De patiënten dienen stipt op tijd te komen, niet eerder en niet later
                        <br/>
                        ⁃ We hebben voorlopig aangepaste openingstijden en gaan gefaseerd opstarten
                        <br/>
                        <br/>

                        Patiënten die tot nu toe afspraken hadden staan en die wegens de coronacrisis niet door zijn gegaan, kunnen bellen of mailen voor een nieuwe afspraak.
                        <br/>
                        <br/>

                        Met vriendelijke groeten,
                        <br/>
                        <br/>

                        <b> Team Jeugd Tandarts Schiedam</b></p>

                </TextWrapper>

            </TextContainer>

        </Container>
       
        

    )
}

export default CoronaUpdate